import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Link } from '../components/link'
import { ExecutivePageQuery } from 'generated/types/gatsby'
import useGovernancePage from '../utils/use-governance-page'
import Layout from '../layouts/index'

interface ExecutivePageProps {
  data: ExecutivePageQuery;
  props: PageProps;
}

const ExecutivePageTemplate: React.FC<ExecutivePageProps> =
  function ExecutivePageTemplate({ data, props }) {
    const executive = data.executive
    const mgmt = data.management.edges
    const governancePage = useGovernancePage()

    const location = {
      pathname: '/governance/corporate-management'
    }

  return (
      <Layout
        location={location}
        heros={governancePage.hero}
        herosFallbackTitle={governancePage.title}
        herosFallbackSecondaryTitle="Corporate Management"
        tabs="governance"
      >
        <div className="container flex flex-col px-4 py-12 mx-auto md:flex-row sm:px-6 lg:px-8 lg:pb-20 lg:pt-8 xl:px-12">
          <div className="mb-10 overflow-x-auto md:pr-6 lg:pr-20 md:w-1/3 tabs-vertical">
            <ul className="flex flex-row items-start -mt-4 overflow-x-hidden divide-y divide-gray-200 flex-nowrap w-max md:w-full md:flex-col dark:divide-gray-700">
              {mgmt.map(({ node }) => {
                return (
                  <li
                    className="block w-64 px-2 py-4 mb-2 md:py-6 md:w-full"
                    key={node.name}
                  >
                    <Link
                      to={`/governance/corporate-management/${node.slug}`}
                      activeClassName="text-primary"
                    >
                      <div className="space-y-1 leading-6">
                        <h3 className="font-bold">{node.name}</h3>
                        <span className="block text-sm text-gray-500 max-w-24">
                          {node.title}
                        </span>
                        <span className="block text-sm font-bold read-more text-primary">
                          Read More →
                        </span>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="w-full px-2 executive-details md:w-2/3">
            <div className="prose dark:prose-dark">
              <h2 className="block executive-name">{executive.name}</h2>
              <span className="relative block text-xs tracking-wide uppercase font-univers -top-2">
                {executive.title}
              </span>
              {/* <div className="executive-picture">
              <GatsbyImage image={executiveImage} alt={executive.name} />
            </div> */}
              <div
                className="mx-auto executive-description"
                dangerouslySetInnerHTML={{
                  __html: executive.biography?.childMarkdownRemark?.html
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }

export default ExecutivePageTemplate

export const query = graphql`
  query ExecutiveBySlug($slug: String!) {
    executive: contentfulPeople(slug: { eq: $slug }) {
      name
      title
      picture {
        gatsbyImageData(width: 240, backgroundColor: "rgb:000000")
      }
      biography {
        childMarkdownRemark {
          html
        }
      }
    }
    management: allContentfulPeople(
      filter: { type: { in: "Executive" } }
      sort: {order: [DESC, ASC], fields: [sticky, weight] }
    ) {
      edges {
        node {
          name
          title
          slug
          picture {
            gatsbyImageData(width: 180, resizingBehavior: SCALE)
          }
        }
      }
    }
  }
`
